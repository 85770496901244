import React from 'react'
const CTA = () => {
  return (
    <div className="cta">
        <a href="#contact" className='btn btn-primary cta-button'>Zum Kontakt</a>

        </div>

  )
}

export default CTA